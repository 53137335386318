import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Balance } from '../../global';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getBalance } from '../../services/slot';
import { logout } from '../../store/authSlice';
import { Button } from '../bootstrap';

let interval: NodeJS.Timeout;

const LoginBtnSection: React.FC = () => {
  const [balance, setBalance] = React.useState<Balance>({ amount: 0, currency: '' });
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const fetchBalance = async () => {
    try {
      const balance = await getBalance(token || '');

      setBalance(balance);
    } catch (err) {
      if ((err as AxiosError).response?.status === 401) {
        dispatch(logout());
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchBalance();
      interval = setInterval(async () => {
        fetchBalance();
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <div className="d-flex align-items-center">
        {balance && (
          <div className="me-4">
            {balance.amount} {balance.currency}
          </div>
        )}
        <Button onClick={() => dispatch(logout())}>Logout</Button>
      </div>
    );
  }

  return (
    <Link to="/login" className="btn btn-primary">
      Login
    </Link>
  );
};

export default LoginBtnSection;
